<template>
    <div class="d-flex flex-column">
        <v-text-field 
            v-model="$v.form.year.$model"
            :error-messages="getErrors('form.year')"
            label="Календарный год" 
            name="year" 
            type="number" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field>

        <v-select
            v-model="$v.form.category.$model"
            :error-messages="getErrors('form.category')"
            required
            :items="categories"
            label="Категория"
        />
        <div>
            <v-btn
                dark 
                small
                :color="$const.color.primary" 
                :loading="loading" 
                @click.prevent.stop="submit"
            >
                Скачать
            </v-btn>
        </div>

        <v-alert
            v-if="summaryError"
            dense
            type="error"
            class="mt-5"
        >
            {{ serverErrors }}
        </v-alert>
    </div>
</template>

<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            loading: false,
            form: {
                year: (new Date()).getFullYear(),
                category: null
            }
        }
    },
    validations() {
        return {
            form: {
                year: { required },
                category: { required }
            }
        }
    },
    computed: {
        categories () {
            return [
                { text: 'ВПР', value: 'test' },
                { text: 'КР', value: 'control' }
            ]
        }
    },
    methods: {
        async submit () {
            try {

                if (this.loading) { return false; }

                if (!this.validate()) {
                    console.log('Validation failed')
                    return false;
                }
            
                this.loading = true
                
                const response = await this.$store.dispatch('report/allTasksThemes', this.form)
                
                if (response.type !== 'text/csv') { throw new Error('Wrong response type.') }
                
                let exportUrl = URL.createObjectURL(response);
                window.location.assign(exportUrl);
                URL.revokeObjectURL(exportUrl);
            
            } catch (e) {
            
                console.error(e)

                this.serverErrors = e
            
            } finally {
            
                this.loading = false
            
            }
        },
        checkFormGradeAvailability () {

            if (!this.grades.includes(this.form.grade))
                this.form.grade = null
        }
    }
}
</script>