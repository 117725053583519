<template>
    <div class="d-flex flex-column w-100 mx-auto" style="max-width: 800px;">
        <p>Скачать csv файл с данными о назначенных темах к заданиям</p>
        <download-all-themes-form />
    </div>
</template>

<script>
import DownloadAllThemesForm from '@/components/forms/DownloadAllThemesForm.vue'

export default {
    components: { DownloadAllThemesForm }
}
</script>